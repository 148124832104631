import { useCallback, useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

import { AppBar, Button, ClickAwayListener, Collapse, makeStyles, Toolbar, useMediaQuery } from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";

import { HamburgerButton } from "./HamburgerButton";
import { MenuButton } from "../components/MenuButton";
import { useUser } from "../user/UserProvider";
import { useLinks } from "../LinkProvider";
import { AskForHelpForm, AskForHelpFormIds, useAskForHelpDialog } from "../components/form-item/client/application/AskForHelpDialog";
import { FormProps } from "../components/form-item/framework/react/FormProvider";
import { TextAreaFormItem } from "../components/form-item/client/components";
import { FormItem } from "../components/form-item/framework/core/form-item.model";
import { FormController } from "../components/form-item/framework/core/form.controller";
import { TaskResponseData, useTaskResponseAction } from "../task/task-response-action.hook";
import { useTranslation } from "../internationalisation/translation.hook";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "#1C5B13",
    },
    container: {
        display: "flex",
        justifyContent: "space-between",
    },
    logoLink: {
        display: "flex",
        alignItems: "center",
        transition: "opacity 0.15s linear",
        padding: "0 16px",

        "&:hover": {
            opacity: 0.7,
        },

        [theme.breakpoints.down(925)]: {
            padding: "0 10px",
        },
    },
    logo: {
        height: "30px",

        [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
            height: "24px",
        },
    },
    menu: {
        display: "flex",
        alignItems: "center",

        [theme.breakpoints.down(925)]: {
            display: "none",
        },

        "& > *": {
            marginRight: "32px",

            "&:last-child": {
                marginRight: 0,
            },
        },
        "& ul": {
            minWidth: "128px",
            padding: "8px 0",

            "& > li": {
                padding: 0,
            },

            "& > li > a": {
                padding: "8px 16px",
                width: "100%",
                fontSize: "14px",
                fontWeight: 500,
                color: "#4A4A4A",
                textDecoration: "none",
            },
        },
    },
    menuButton: {
        fontSize: "14px",
        fontWeight: 600,
        letterSpacing: "0.75px",
        color: "rgba(255, 255, 255, 0.7)",
        textDecoration: "none",
        textTransform: "none",
        transition: "color 0.2s linear",
        padding: "16px",

        "&:hover": {
            backgroundColor: "transparent",
            color: "#ffffff",
        },

        "&.active": {
            color: "#ffffff",
        },
    },
    hamburger: {
        [theme.breakpoints.up(925)]: {
            display: "none",
        },
    },
    drawer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#297A1D",
        padding: "8px",

        "& > a": {
            fontSize: "14px",
            fontWeight: 600,
            letterSpacing: "0.75px",
            color: "rgba(255, 255, 255, 0.7)",
            textDecoration: "none",
            textTransform: "uppercase",
            transition: "color 0.2s linear",
            padding: "8px",

            "&.active, &:hover": {
                color: "#ffffff",
            },
        },
    },
    verticalDivider: {
        marginBottom: "32px",
    },
    separator: theme.mixins.toolbar,
}));

// TODO: Separate navigations and topbar
// TODO: Create a components folder and let it include topbar and sidebar
// TODO: Create a layout folder and let it include layout, sidenavigation and topnavigation

interface TopbarProps {
    hideSidebar?: boolean;
    hideGuides?: boolean;
}

export const Topbar = (props: TopbarProps) => {
    const classes = useStyles();
    const { hideSidebar, hideGuides } = props;

    const { t } = useTranslation();

    const [active, setActive] = useState(false);

    const toggleActive = useCallback(() => {
        setActive(!active);
    }, [active]);

    const closeDrawer = useCallback(() => {
        setActive(false);
    }, []);

    const location = useLocation();
    useEffect(() => {
        setActive(false);
    }, [location]);

    const disableHamburger = useMediaQuery("(min-width: 925px)");
    useEffect(() => {
        if ( !disableHamburger ) return;

        setActive(false);
    }, [disableHamburger]);

    const links = useLinks();
    const gardener = useUser();
    const userFirstName = gardener.name.split(" ")[0];

    const taskResponseAction = useTaskResponseAction();
    const askForHelpActionAdapter = useCallback<FormProps<AskForHelpForm>["onSubmit"]>((controller, form): void => {
        const data = mapAskForHelpFormToTaskResponseData(controller, form);

        taskResponseAction(data);
    }, [taskResponseAction]);

    const openAskForHelpDialog = useAskForHelpDialog(askForHelpActionAdapter);

    if ( !links ) return null;

    const { sideLinks, topLinks, logOutLink } = links;

    const relevantTopLinks = topLinks.filter(x => {
        if ( x === "guide-button" ) return !hideGuides;

        return true;
    });

    return (
        <>
            <ClickAwayListener onClickAway={closeDrawer}>

                <AppBar position="fixed" component="nav" className={classes.root}>
                    <Toolbar className={classes.container}>

                        <Link to="/" className={classes.logoLink}>
                            <img src="/logo.png" alt="Go Go Garden logo" className={classes.logo} />
                        </Link>

                        <div className={classes.menu}>

                            {!hideGuides ? <Link to="/guides" className={classes.menuButton}>{t("TopBar: guides-link")}</Link> : null}

                            <Button onClick={openAskForHelpDialog} className={classes.menuButton}>{t("TopBar: ask-for-help-link")}</Button>

                            <MenuButton
                                id="user-menu"
                                text={userFirstName}
                                hide={!disableHamburger}
                                disableRipple
                                startIcon={<AccountCircle />}
                                endIcon={<ArrowDropDown />}
                                className={classes.menuButton}
                            >
                                {topLinks.filter(x => typeof x !== "string")}
                                {logOutLink}
                            </MenuButton>
                        </div>

                        <HamburgerButton active={active} toggle={toggleActive} className={classes.hamburger} />

                    </Toolbar>

                    <Collapse in={active} timeout={400}>
                        <div className={classes.drawer}>
                            {!hideSidebar ? sideLinks : null}

                            {!hideSidebar && relevantTopLinks.length > 0 ? <div className={classes.verticalDivider} /> : null}
                            {relevantTopLinks.map(x => {
                                if ( typeof x !== "string" ) return x;

                                switch ( x ) {
                                    case "help-button": return <Link key="ask-for-help" to="#" onClick={openAskForHelpDialog}>{t("TopBar: ask-for-help-link")}</Link>;
                                    case "guide-button": return <NavLink key="guides" to="/guides" exact>{t("TopBar: guides-link")}</NavLink>;
                                }
                            })}

                            {logOutLink ? <div className={classes.verticalDivider} /> : null}
                            {logOutLink}
                        </div>
                    </Collapse>

                </AppBar>

            </ClickAwayListener>

            <div className={classes.separator} />
        </>
    );
};

const mapAskForHelpFormToTaskResponseData = (controller: FormController, form: FormItem): TaskResponseData => {
    const message = (controller.getItem(AskForHelpFormIds.TextAreaMessage, form) as TextAreaFormItem).value;

    return {
        action: "ask for help",
        message,
    };
};
