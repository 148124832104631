import { PropsWithChildren, useMemo } from "react";

import { useUser } from "../user/UserProvider";
import { TutorialPage } from "./TutorialPage";
import { useTaskResponseAction } from "../task/task-response-action.hook";
import { Tutorial } from "../gardener/gardener.model";
import { Layout } from "../application/Layout";
import { useLocation } from "react-router-dom";

export const GlobalBlockingTutorialRouter = (props: PropsWithChildren<{}>) => {
    const { children } = props;

    const taskResponseAction = useTaskResponseAction();

    const gardener = useUser();

    const location = useLocation();

    const exemptLocation = useMemo(() => {
        if ( location.pathname === "/profile" ) return true;
        if ( location.pathname === "/agreements/create" ) return true;

        return false;
    }, [location.pathname]);

    const guide = useMemo<keyof Tutorial | null>(() => {
        if ( gardener.status !== "active" ) return null;

        if ( gardener.tutorial.onboarding === "relevant" ) return "onboarding";
        if ( gardener.tutorial.appointmentTask === "relevant" ) return "appointmentTask";
        if ( gardener.tutorial.registrationTask === "relevant" ) return "registrationTask";
        if ( gardener.tutorial.afterRegistration === "relevant" ) return "afterRegistration";
        if ( gardener.tutorial.afterClosedTask === "relevant" ) return "afterClosedTask";

        return null;
    }, [gardener]);

    if ( exemptLocation || !guide ) return <>{children}</>;

    return (
        <Layout hideSidebar hideStatusBanner hideGuides>
            <TutorialPage guide={guide} taskResponseAction={taskResponseAction} />
        </Layout>
    );
};
