import { makeStyles } from "@material-ui/core";
import moment from "moment";

import { MatchDocument } from "../customer/match.model";
import { CardContent } from "../components/card/CardContent";
import { Card, CardHeader } from "../components/card";
import { AppointmentDocument } from "./appointment.model";
import { GardeningTaskGardenerViewDocument } from "../gardening-task/gardener-view/gardening-task-gardener-view.model";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
    content: {
        "& > *": {
            marginBottom: "16px",
        },

        "& > *:last-child": {
            marginBottom: 0,
        }
    },
    link: {
        fontSize: "16px",
        fontWeight: 500,
        color: "#297A1D",
        textDecoration: "none",

        "&:hover": {
            color: "#1C5B13",
            textDecoration: "underline",
        },
    },
    dottedSeparator: {
        backgroundImage: "url(\"data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cline x1='-10%25' x2='110%25' y1='0' y2='0' fill='none' rx='4' ry='4' stroke='%23818181' stroke-width='2' stroke-dasharray='1%2c 4' stroke-dashoffset='4' stroke-linecap='butt'/%3e%3c/svg%3e\")",
        height: "1px",
    },
    group: {
        display: "flex",
        flexDirection: "column",
        marginBottom: "24px",

        "&:last-child": {
            marginBottom: 0,
        },

        "& > label": {
            fontSize: "14px",
            fontWeight: 500,
            color: "#1C5B13",
            marginBottom: "4px",
        },

        "& > span": {
            fontSize: "16px",
            fontWeight: 400,
            color: "#4A4A4A",
            lineHeight: "125%",
            whiteSpace: "pre-wrap",

            "& > a": {
                fontSize: "16px",
                fontWeight: 500,
                color: "#297A1D",
                textDecoration: "none",
        
                "&:hover": {
                    color: "#1C5B13",
                    textDecoration: "underline",
                },
            },
        },
    },
});

interface AppointmentCardProps {
    status?: JSX.Element;
    title: string;
    gardeningTask: GardeningTaskGardenerViewDocument;
    match: MatchDocument;
    appointment: AppointmentDocument;
}

export const AppointmentCard = (props: AppointmentCardProps) => {
    const classes = useStyles();

    const { status, title, gardeningTask, match, appointment } = props;

    return (
        <Card>

            {status}

            <CardHeader title={title} />

            <CardContent className={classes.content}>

                {title !== appointment.customer.name ? (
                    <div>{appointment.customer.name} (<Link to={`/customers/current/${match.id}`} className={classes.link}>Se opgave</Link>)</div>
                ) : null}

                {gardeningTask.customer.phoneNumber ? (
                    <div>
                        <a href={phoneNumberHref(gardeningTask.customer.phoneNumber)} className={classes.link}>
                            {gardeningTask.customer.phoneNumber}
                        </a>
                    </div>
                ) : null}

                <div>
                    <a href={gpsHref(gardeningTask.data.address)} target="_blank" rel="noreferrer" className={classes.link}>
                        {gardeningTask.data.address.street}<br />
                        {gardeningTask.data.address.zipCode}
                    </a>
                </div>
                
                <div className={classes.dottedSeparator} />

                <div className={classes.group}>
                    <label>Hvornår skal du på besøg hos kunden?</label>
                    <span>{formatDate(appointment.scheduledDate)}</span>
                </div>

                <div className={classes.group}>
                    <label>Hvad skal du lave hos kunden?</label>
                    <span>{appointment.description}</span>
                </div>

            </CardContent>

        </Card>
    );
};

function formatDate(date: string): string {
    const day = moment(date).format("dddd")
    const formattedDate = moment(date).format("D MMMM YYYY");

    return `${day.slice(0, 1).toUpperCase() + day.slice(1)} d. ${formattedDate}`;
}

function phoneNumberHref(phoneNumber: string): string {
    return `tel:${phoneNumber}`;
}

function gpsHref(address: MatchDocument["customer"]["address"]): string {
    const query = `${address.street}, ${address.zipCode}`;

    return encodeURI(`https://maps.google.com/?q=${query}`);
}
