export const customerPrices = {
    customerTools: 360,
    gardenerTools: 396,
    gardenerLargeTools: 540,
};

export const gardenerPrices = {
    customerTools: 180,
    gardenerTools: 216,
    gardenerLargeTools: 360,
};

export const gardenerPricesIfVatLiable = {
    customerTools: 225,
    gardenerTools: 270,
    gardenerLargeTools: 360,
};
