import { PropsWithChildren, useCallback, useMemo, useState } from "react";

import { Collapse, makeStyles } from "@material-ui/core";

import { Topbar } from "./Topbar";
import { SideNavigation } from "./SideNavigation";
import { useUser } from "../user/UserProvider";
import { StatusBanner } from "../gardener/StatusBanner";
import { Dac7Banner } from "../gardener/Dac7Banner";

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
    },

    container: {
        flexGrow: 1,
        display: "flex",
    },

    main: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
    },
    pushNextBanner: {
        marginBottom: "4px",
    },
});

interface LayoutProps {
    hideSidebar?: boolean;
    hideStatusBanner?: boolean;
    hideGuides?: boolean;
}

export const Layout = (props: PropsWithChildren<LayoutProps>) => {
    const classes = useStyles();
    const { hideSidebar, hideStatusBanner, hideGuides, children } = props;

    const gardener = useUser();

    const shouldShowDac7Banner = gardener.dac7.requestActive && !gardener.dac7.reviewActive;

    const paused = gardener.protections.includes("manual-gardener") || gardener.protections.includes("no-reply") || gardener.protections.includes("picky-gardener") || gardener.protections.includes("process");
    const shouldShowStatusBanner = !hideStatusBanner && (gardener.status === "terminated" || paused);

    const [showingDac7Banner, setShowingDac7Banner] = useState(shouldShowDac7Banner);
    const willEnterDac7Banner = useCallback(() => {
        setShowingDac7Banner(true);
    }, []);
    const didExitDac7Banner = useCallback(() => {
        setShowingDac7Banner(false);
    }, []);

    const [showingStatusBanner, setShowingStatusBanner] = useState(shouldShowStatusBanner);
    const willEnterStatusBanner = useCallback(() => {
        setShowingStatusBanner(true);
    }, []);

    const didExitStatusBanner = useCallback(() => {
        setShowingStatusBanner(false);
    }, []);

    const dac7BannerClasses = useMemo(() => {
        if ( !showingDac7Banner || !showingStatusBanner ) return undefined;

        return { wrapperInner: classes.pushNextBanner };
    }, [showingDac7Banner, showingStatusBanner, classes.pushNextBanner])

    return (
        <div className={classes.root}>
            <header>
                <Topbar hideSidebar={hideSidebar} hideGuides={hideGuides} />
            </header>

            <div className={classes.container}>
                {!hideSidebar ? <SideNavigation /> : null}

                <main className={classes.main}>

                    <Collapse appear={false} in={shouldShowDac7Banner} classes={dac7BannerClasses} onEnter={willEnterDac7Banner} onExited={didExitDac7Banner}>
                        <Dac7Banner enabled={shouldShowDac7Banner} />
                    </Collapse>

                    <Collapse appear={false} in={shouldShowStatusBanner} onEnter={willEnterStatusBanner} onExited={didExitStatusBanner}>
                        <StatusBanner enabled={shouldShowStatusBanner} />
                    </Collapse>

                    {children}
                </main>
            </div>
        </div>
    );
};
