import { PropsWithChildren, useMemo, useRef } from "react";

import moment from "moment";

import { useUser } from "../user/UserProvider";
import { TutorialPage } from "./TutorialPage";
import { useTaskResponseAction } from "../task/task-response-action.hook";
import { Tutorial } from "../gardener/gardener.model";
import { Layout } from "../application/Layout";
import { AppointmentFollowUpTaskDocument, OfferResponseTaskDocument, RegistrationFollowUpTaskDocument } from "../task/task.model";

export interface TaskBlockingTutorialRouterProps {
    task: OfferResponseTaskDocument | AppointmentFollowUpTaskDocument | RegistrationFollowUpTaskDocument;
    goToOverview: () => void;
}

export const TaskBlockingTutorialRouter = (props: PropsWithChildren<TaskBlockingTutorialRouterProps>) => {
    const { goToOverview, children } = props;
    const task = useRef(props.task).current; // If guide should be presented and task changes status in the mean time, we do not want to disrupt the guide.

    const taskResponseAction = useTaskResponseAction();

    const gardener = useUser();
    const now = useRef(moment().toISOString(true)).current;

    const guide = useMemo<keyof Tutorial | null>(() => {
        if ( gardener.status !== "active" ) return null;

        if ( task.type === "offer-response" && !task.hasBeenPerformed && !task.reasonForClosing && gardener.tutorial.newTask === "relevant" ) return "newTask";
        if ( task.type === "appointment-follow-up" && !task.hasBeenPerformed && gardener.tutorial.customerConversation === "relevant" ) return "customerConversation";
        if ( task.type === "registration-follow-up" && !task.hasBeenPerformed && moment(task.performAfterDate).isSameOrBefore(now, "day") && gardener.tutorial.registration === "relevant" ) return "registration";

        return null;
    }, [gardener, task, now]);

    if ( !guide ) return <>{children}</>;

    return (
        <Layout hideSidebar hideStatusBanner hideGuides>
            <TutorialPage guide={guide} goBack={goToOverview} taskResponseAction={taskResponseAction} />
        </Layout>
    );
};
