import { Container } from "@material-ui/core";

import { ContentSection } from "../../application/ContentSection";
import { VerticalButtons, GreenButton } from "../../components/new-button";
import { Section } from "../../components/section/Section";
import { useTranslation } from "../../internationalisation/translation.hook";
import { CardStatus, CardStatusProps } from "../../components/card";
import { formatTaskDate } from "../shared/utility";
import { RegistrationFollowUpTaskDocument, TaskDocument } from "../../task/task.model";
import { AppointmentCard } from "../../appointment/AppointmentCard";
import { AppointmentDocument } from "../../appointment/appointment.model";
import { useCallback } from "react";
import { SectionHeader } from "../../components/section/SectionHeader";
import { determineDaysUntilTaskDeadline } from "../../utility";
import { RegistrationTaskResponseDialogIds, useRegistrationTaskResponseDialog } from "../../components/form-item/client/application/registration-task-dialog";
import { FormProps } from "../../components/form-item/framework/react/FormProvider";
import { FormItem } from "../../components/form-item/framework/core/form-item.model";
import { mapRejectCustomerBranchToTaskResponseData } from "../appointment-task/AppointmentTaskDetailPage";
import { TaskResponseData } from "../../task/task-response-action.hook";
import { SingleChoiceFormItem, DatePickerFormItem, RegistrationFormItem, TextAreaFormItem } from "../../components/form-item/client/components";
import { FormController } from "../../components/form-item/framework/core/form.controller";
import { Task } from "../../registration/registration.model";
import moment from "moment";
import { FloatingButton } from "../../components/floating-button/FloatingButton";
import { GardeningTaskGardenerViewDocument } from "../../gardening-task/gardener-view/gardening-task-gardener-view.model";
import { RegistrationTaskGuideList } from "../../guides/RegistrationTaskGuideList";
import { GardenerDocument } from "../../gardener/gardener.model";
import { MatchDocument } from "../../customer/match.model";

interface RegistrationTaskDetailPageProps {
    gardener: GardenerDocument
    task: RegistrationFollowUpTaskDocument;
    gardeningTask: GardeningTaskGardenerViewDocument;
    match: MatchDocument;
    appointment: AppointmentDocument;
    hasHistory: boolean;
    hasRecentHistory: boolean;
    hasMultipleAppointments: boolean;
    now: string;
    taskResponseAction: (data: TaskResponseData | string) => void;
}

export const RegistrationTaskDetailPage = (props: RegistrationTaskDetailPageProps) => {
    const { gardener, task, gardeningTask, match, appointment, hasHistory, hasRecentHistory, hasMultipleAppointments, now, taskResponseAction } = props;

    const { t } = useTranslation();

    const isFuture = moment(task.performAfterDate).isAfter(now, "day");
    const registrationTaskResponseActionAdapter = useCallback<FormProps<FormItem>["onSubmit"]>((controller, form): void => {
        const data = mapRegistrationTaskResponseFormToTaskResponseData(controller, form, task, isFuture);

        taskResponseAction(data);
    }, [taskResponseAction, task, isFuture]);

    const openRegistrationTaskResponseDialog = useRegistrationTaskResponseDialog(hasHistory, hasRecentHistory, hasMultipleAppointments, isFuture, gardeningTask, appointment, now, registrationTaskResponseActionAdapter);

    return (
        <ContentSection>

            <Container maxWidth="sm" disableGutters>

                <Section>

                    <AppointmentCard
                        status={(
                            <>
                                <CardStatus color={determineStatusColor(task, now)} status={formatTaskDate(task, now, t)} />
                            </>
                        )}
                        title={t("CurrentTasksTab: registration-task-title")}
                        gardeningTask={gardeningTask}
                        match={match}
                        appointment={appointment}
                    />

                    <FloatingButton>
                        <VerticalButtons>
                            <GreenButton onClick={openRegistrationTaskResponseDialog}>{t("CurrentTasksTab: task-response-action")}</GreenButton>
                        </VerticalButtons>
                    </FloatingButton>

                </Section>

            </Container>

            <Container maxWidth="md" disableGutters>
                <Section>
                    <SectionHeader>Relevante guides</SectionHeader>
                    <RegistrationTaskGuideList gardener={gardener} taskResponseAction={taskResponseAction} />
                </Section>
            </Container>

        </ContentSection>
    );
};

function determineStatusColor(task: TaskDocument, now: string): CardStatusProps["color"] {
    const days = determineDaysUntilTaskDeadline(task, now);

    if ( days > 0 ) return "default";
    if ( days === 0 ) return "warning";

    return "danger";
}

const mapRegistrationTaskResponseFormToTaskResponseData = (controller: FormController, form: FormItem, task: RegistrationFollowUpTaskDocument, isFuture: boolean): TaskResponseData | string => {
    const selectedActionIndex = (controller.getItem(RegistrationTaskResponseDialogIds.SingleChoiceAction, form) as SingleChoiceFormItem<FormItem[]>).selectedChoiceIndex;
    if ( selectedActionIndex === null ) return "expected selected action";

    const actions = !isFuture ? ["did visit" as const, "change date" as const, "cancel appointment" as const, "close task" as const] : ["change date" as const, "cancel appointment" as const, "close task" as const];
    const selectedAction = actions[selectedActionIndex];

    if ( selectedAction === "did visit" ) {
        const didDoGardening = (controller.getItem(RegistrationTaskResponseDialogIds.DidGardenerDoGardening, form) as SingleChoiceFormItem<FormItem[]>).selectedChoiceIndex === 1;
        if ( didDoGardening ) {
            const registrationForm = (controller.getItem(RegistrationTaskResponseDialogIds.RegistrationForm, form) as RegistrationFormItem);
            const tasks: Task[] = registrationForm.tasks.map(task => ({
                description: task.description,
                durationInMinutes: task.duration!,
                tools: task.tools!,
            }));

            return {
                action: "create registration",
                taskId: task.id,
                tasks,
            };
        }

        const selectedInspectionActionIndex = (controller.getItem(RegistrationTaskResponseDialogIds.SingleChoiceInspectionAction, form) as SingleChoiceFormItem<FormItem[]>).selectedChoiceIndex;
        if ( selectedInspectionActionIndex === null ) return "expected selected inspection action";

        const inspectionActions = ["did create appointment" as const, "did not create appointment" as const, "close task" as const];
        const selectedInspectionAction = inspectionActions[selectedInspectionActionIndex];

        if ( selectedInspectionAction === "did create appointment" ) {
            const date = (controller.getItem(RegistrationTaskResponseDialogIds.DidVisitAppointmentDate, form) as DatePickerFormItem).value;
            const description = (controller.getItem(RegistrationTaskResponseDialogIds.DidVisitAppointmentDescription, form) as TextAreaFormItem).value;
    
            return {
                action: "update appointment",
                taskId: task.id,
                hasVisited: true,
                date,
                description,
            };
        }

        if ( selectedInspectionAction === "did not create appointment" ) {
            return {
                action: "cancel appointment",
                taskId: task.id,
                hasVisited: true,
            };
        }

        if ( selectedInspectionAction === "close task" ) {
            return mapRejectCustomerBranchToTaskResponseData(controller, form, task, {
                singleChoiceDoesCustomerStillWantHelp: RegistrationTaskResponseDialogIds.DidVisitSingleChoiceDoesCustomerStillWantHelp,
            });
        }

        return "unknown inspection action";
    }

    if ( selectedAction === "change date" ) {
        const date = (controller.getItem(RegistrationTaskResponseDialogIds.DidNotVisitAppointmentDate, form) as DatePickerFormItem).value;
        const description = (controller.getItem(RegistrationTaskResponseDialogIds.DidNotVisitAppointmentDescription, form) as TextAreaFormItem).value;
    
        return {
            action: "update appointment",
            taskId: task.id,
            hasVisited: false,
            date,
            description,
        };
    }

    if ( selectedAction === "cancel appointment" ) {
        return {
            action: "cancel appointment",
            taskId: task.id,
            hasVisited: false,
        };
    }

    if ( selectedAction === "close task" ) {
        return mapRejectCustomerBranchToTaskResponseData(controller, form, task, {
            singleChoiceDoesCustomerStillWantHelp: RegistrationTaskResponseDialogIds.DidNotVisitSingleChoiceDoesCustomerStillWantHelp,
        });
    }

    return "unknown action";
};
