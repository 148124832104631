import { Container } from "@material-ui/core";
import { useCallback, useMemo } from "react";

import moment from "moment";

import { ContentSection } from "../../application/ContentSection";
import { useTranslation, Translation } from "../../internationalisation/translation.hook";
import { ListSectionAlt, ListSectionAltProps } from "../../components/list-section-alt/ListSectionAlt";
import { determineDaysUntilTaskDeadline, formatDate } from "../../utility";
import { TaskDocument } from "../../task/task.model";
import { Notice } from "../shared/Notice";
import { formatNewTaskDate } from "../shared/utility";
import { Section } from "../../components/section/Section";
import { SectionHeader } from "../../components/section/SectionHeader";
import { OverviewGuideList } from "../../guides/OverviewGuideList";
import { GardenerDocument } from "../../gardener/gardener.model";
import { TaskResponseData } from "../../task/task-response-action.hook";

type Task = Extract<TaskDocument, { type: "offer-response" | "appointment-follow-up" | "registration-follow-up" }>;

interface CurrentTasksTabProps {
    goToTask: (task: Task) => void;
    taskResponseAction: (data: TaskResponseData | string) => void;
    gardener: GardenerDocument;
    tasks: Task[];
    now: string;
}

export const CurrentTasksTab = (props: CurrentTasksTabProps) => {
    const { t } = useTranslation();

    const { goToTask, taskResponseAction, gardener, tasks, now } = props;

    const { violatedTasks, todayTasks, tomorrowTasks } = useMemo(() => {
        const tomorrow = moment(now).add(1, "day").toISOString(true);

        const violatedTasks = tasks.filter(task => moment(task.performAfterDate).isBefore(now, "day")).sort(sortTaskByDateAndCustomerName);
        const todayTasks = tasks.filter(task => task.type === "offer-response" || moment(task.performAfterDate).isSame(now, "day")).sort(sortTaskByTypeDateAndCustomerName);
        const tomorrowTasks = tasks.filter(task => task.type !== "offer-response" && moment(task.performAfterDate).isSame(tomorrow, "day")).sort(sortTaskByDateAndCustomerName);

        return { violatedTasks, todayTasks, tomorrowTasks };
    }, [tasks, now]);

    const goToViolatedTask = useCallback(index => goToTask(violatedTasks[index]), [goToTask, violatedTasks]);
    const goToTodayTask = useCallback(index => goToTask(todayTasks[index]), [goToTask, todayTasks]);
    const goToTomorrowTask = useCallback(index => goToTask(tomorrowTasks[index]), [goToTask, tomorrowTasks]);

    const violatedTaskItems = useMemo(() => violatedTasks.map(task => mapTaskToTaskItem(task, now, t)), [violatedTasks, now, t]);
    const todayTaskItems = useMemo(() => todayTasks.map(task => mapTaskToTaskItem(task, now, t)), [todayTasks, now, t]);
    const tomorrowTaskItems = useMemo(() => tomorrowTasks.map(task => mapTaskToTaskItem(task, now, t)), [tomorrowTasks, now, t]);

    return (
        <ContentSection>

            <Container maxWidth="sm" disableGutters>

                {violatedTaskItems.length > 0 ? (
                    <ListSectionAlt
                        title={t("CurrentTasksTab: exceeded-deadline-header")}
                        placeholder=""
                        items={violatedTaskItems}
                        onClick={goToViolatedTask}
                    />
                ) : null}

                <ListSectionAlt
                    title={t("CurrentTasksTab: today-header")}
                    placeholder={t("CurrentTasksTab: today-placeholder")}
                    items={todayTaskItems}
                    onClick={goToTodayTask}
                />

                <ListSectionAlt
                    title={t("CurrentTasksTab: tomorrow-header")}
                    placeholder={t("CurrentTasksTab: tomorrow-placeholder")}
                    items={tomorrowTaskItems}
                    onClick={goToTomorrowTask}
                />

            </Container>

            <Container maxWidth="md" disableGutters>
                <Section>
                    <SectionHeader>Relevante guides</SectionHeader>
                    <OverviewGuideList gardener={gardener} taskResponseAction={taskResponseAction} />
                </Section>
            </Container>

        </ContentSection>
    );
};

function formatNewTaskSecondaryHeading(date: string, now: string, t: Translation): JSX.Element {
    const text = formatNewTaskDate(date, now, t);

    const hours = moment(date).diff(now, "hours");

    return hours >= 20 ? <>{text}</> : <Notice>{text}</Notice>;
}

function formatTaskDate(task: TaskDocument, now: string, t: Translation): JSX.Element {
    const days = determineDaysUntilTaskDeadline(task, now);
    if ( days > 1 ) return <>{t("CurrentTasksTab: response-future").replaceAll("$DATE", formatDate(task.performAfterDate))}</>;
    if ( days === 1 ) return <>{t("CurrentTasksTab: response-tomorrow")}</>
    if ( days === 0 ) return <>{t("CurrentTasksTab: response-today")}</>
    if ( days === -1 ) return <>{t("CurrentTasksTab: response-missing")} <Notice>({t("CurrentTasksTab: days-ago-singular")})</Notice></>

    return <>{t("CurrentTasksTab: response-missing")} <Notice>({t("CurrentTasksTab: days-ago-plural").replaceAll("$DAYS", Math.abs(days).toString())})</Notice></>
}

export function sortTaskByDateAndCustomerName(a: Task, b: Task): number {
    if ( a.performAfterDate < b.performAfterDate ) return -1;
    if ( a.performAfterDate > b.performAfterDate ) return 1;

    if ( a.customerName < b.customerName ) return -1;
    if ( a.customerName > b.customerName ) return 1;

    return 0;
}

export function sortTaskByTypeDateAndCustomerName(a: Task, b: Task): number {
    const taskTypes: Task["type"][] = ["offer-response", "appointment-follow-up", "registration-follow-up"];

    if ( taskTypes.indexOf(a.type) < taskTypes.indexOf(b.type) ) return -1;
    if ( taskTypes.indexOf(a.type) > taskTypes.indexOf(b.type) ) return 1;

    if ( a.performAfterDate < b.performAfterDate ) return -1;
    if ( a.performAfterDate > b.performAfterDate ) return 1;

    if ( a.customerName < b.customerName ) return -1;
    if ( a.customerName > b.customerName ) return 1;

    return 0;
}

export function mapTaskToTaskItem(task: Task, now: string, t: Translation): ListSectionAltProps["items"][number] {
    switch ( task.type ) {
        case "offer-response":
            return {
                heading: t("CurrentTasksTab: new-task-title"),
                subheading: task.customerName.split(" ")[0],
                secondaryHeading: formatNewTaskSecondaryHeading(task.performAfterDate, now, t),
            };

        case "appointment-follow-up":
            return {
                heading: t("CurrentTasksTab: appointment-task-title"),
                subheading: task.customerName,
                secondaryHeading: formatTaskDate(task, now, t),
            };

        case "registration-follow-up":
            return {
                heading: t("CurrentTasksTab: registration-task-title"),
                subheading: task.customerName,
                secondaryHeading: formatTaskDate(task, now, t),
            };
    }
}
